export const PRIVACY_AND_POLICIES: string[] = [
  "**Who We Are",
  "For the purpose of the general data protection regulation and the data protection act 1998, the data controller is Miindset Ltd, a company registered in England and Wales under company number 12422556. Our registered office is 20-22 Wenlock Road, London, N1 7GU.",

  "**Your Privacy",
  "We take the privacy rights of our users very seriously and seek to ensure the highest standards of compliance with United Kingdom data protection laws and regulations.",
  "Here we have outlined the privacy practices that we adopt, clearly detailing how, when and if we use the information that you provide to us as a user of Miindset. This privacy policy is incorporated in and subject to the terms.",
  "By accessing and continuing to use the app, you accept the terms of this privacy policy. Please note that we may update and amend this privacy policy from time to time and any changes will be posted here. It is your responsibility to check regularly if there have been changes and to review such changes.",
  "If you do not agree with any of our privacy policy, you should not use the app. If you have any questions about our privacy policy, please email us at hello@miindset.com.",

  "**Protecting Your Privacy and Information",
  "At Miindset, we use appropriate administrative, physical and technical safeguards to protect your personal information from loss or theft, unauthorised access, use or disclose, or modification or destruction. We train our personnel to protect your privacy and require them to comply with our policies and procedures that protect your personal information.",
  "We use computing systems in secure facilities to store your personal information in an encrypted form. All of the data you provide to us is securely encrypted using an SSL certificate for the protection of your data. You may at any time, email us at hello@miindset.com to enquire about any of the information we hold.",

  "**How We Collect and Use Information",
  "We require your email address to send an automated registration email out to you. We may collect a CSV file from your organisation in order for us to send out your registration email.",
  "If you choose not to click ‘Register’, your email address will be automatically deleted from our systems after 21 days of our ‘reminder to register’ emails being sent out.",
  "If you click register, we collect your personal information that you provide by filling in the registration form or information on our app (including when you register as a user and when you update your user settings). If you contact us, we may keep a record of that correspondence.",
  "At registration, we collect a username and one email address (an organisation email for registration purposes).",

  "**Your Username",
  "When you choose a username, we ask that you choose one that is not related in any way to your real name so that you remain anonymous.",

  "**Your Emails",
  "As your organisation becomes a member of Miindset, you will receive a registration email to your organisation email address.",
  "If you opt-in, Miindset will use your email to send you recommended weekly updates of the services we think will suit you.",
  "To stop receiving these weekly information emails, you can go to your ‘Account’ tab and opt-out.",

  "**Why We Collect and Store Your Data",
  "We need to ensure that only clients of Miindset are allowed to access the service, personal information helps us to identify who you are within the system and verify that it’s you.",

  "**Where We Store Your Data",
  "All personal information you provide to us is stored and processed on our secure servers location in the UK and the European Economic Area (EEA).",

  "**Information that the Platform Automatically Collects",
  "Our webservers automatically collect information about your visit to the site including, but not limited to, traffic data, location data and other communication data. Although we do not ordinarily set out to identify you from your Internet Protocol (IP) address, we treat IP addresses as personal information. We may use data that our webservers automatically collect about your use of the app, including IP addresses, to: investigate any suspected breaches of, and enforce terms, e.g., to help Us determine if a user has registered more than once, or to investigate usage of the app that may be inappropriate; and to obtain compensation from you or take other action including legal action as set out in the Terms and Conditions.",
  "We use information automatically captured by our web servers that is not personal information to see how the app is being used and customise it for your benefit.",

  "**Cookies",
  "Cookies are small pieces of information stored by your browser on your computer’s hard drive. We use cookies to allow us to understand who has seen which pages, to determine how frequently particular pages are visited, to determine the most popular areas of the app and generally to monitor usage of the app. Most web browsers automatically accept cookies, though you do not have to. We do not control the use of cookies by third parties. If you wish to disable cookies you can do so by readjusting your browser settings. If you disable cookies you will still be able to use the app. For more information on cookies and how to disable them, you can consult the information provided by the Interactive Advertising Bureau at All About Cookies.",

  "**Links",
  "To simplify the sign-up process for all UNidays members, we have implemented a UNiDAYS OAuth tool to aid in this process.",
  "Our app contains links to and from other websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies.",
  "Please be aware that the app may link to other websites that may be accessed by you through the app. We are not responsible for the data policies, content or security of these linked web sites. Please check any policies on such websites before you submit any personal data to them.",

  "**GDPR",
  "Where a data controller has an online presence, the WP29 recommends that the controller provides a privacy notice which is layered. The data subject should have a clear overview of the information available to them and on finding detailed information within the layers of the notice. The Guidelines provide that the first layer should always contain information on the processing which has the most impact on the data subject and processing which could surprise the data subject.",
  "There are various interpretations of that statement, while we understand some clients are taking the GDPR regulations more seriously than others, in accordance with our advice, Sopro has updated our best practice compliance guidelines to require all clients to include a statement in keeping with (or to the effect of) the statement set out below. This is to ensure data subjects are notified of Sopro as an appointed data processor within the terms of their online privacy policy.",

  "**Third Party Processors",
  "Our carefully selected partners and service providers may process personal information about you on our behalf as described below:.",
  "Digital Marketing Service Providers",
  "We periodically appoint digital marketing agents to conduct marketing activity on our behalf, such activity may result in the compliant processing of personal information.  Our appointed data processors include:",
  "(i)Prospect Global Ltd (trading as Sopro) Reg. UK Co. 09648733. You can contact Sopro and view their privacy policy here: http://sopro.io.  Sopro are registered with the ICO Reg: ZA346877 their Data Protection Officer can be emailed at: dpo@sopro.io",
  
  "**Complaints",
  "If you have any complaint about the way in which we collect, store and use your information and these have not been addressed by contacting us first, you can contact the supervisory authority in the United Kingdom, the Information Commissioner’s Office: https://ico.org.uk/concerns/.",

  "**Your Consent to this Privacy Policy",
  "This privacy policy explains to you the use of the app. By clicking ‘Register’ during the registration process you agree to this privacy policy.",
  "This is our entire and exclusive privacy policy and it supersedes any earlier version. This privacy policy should be read in conjunction with the terms and any additional applicable terms posted.",
];
